import React from "react";
import Main from "./Main";
import { LanguageProvider } from "./Context/LanguageContext";
import { Helmet } from "react-helmet"; // Import Helmet

function App() {
  return (
    <LanguageProvider>
      <Helmet>
        <title>Olga Hraboviuk - Hairdresser</title>
        <meta name="description" content="Welcome to Olga Hraboviuk's page." />
      </Helmet>
      <Main />
    </LanguageProvider>
  );
}

export default App;
